<template>
    <div class="bg-page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('银行卡绑定')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="bind-panel">
            <van-form >
                <van-field
                        clickable
                        v-model="bank_text"
                        :label="$t('银行名称')"
                        :placeholder="$t('请输入银行名称')" label-width="40%"
                >
                    <template #label><span class="field-label">{{$t('银行名称')}}</span></template>
                </van-field>
                <van-field  v-model="name" :placeholder="$t('请输入姓名')">
                    <template #label><span class="field-label">{{$t('姓名')}}</span></template>
                </van-field>
                <van-field  v-model="number" :placeholder="$t('请输入银行卡号')" >
                    <template #label><span class="field-label">{{$t('银行卡号')}}</span></template>
                </van-field>
                <van-field  v-model="remark" type="textarea" rows="3" center :label="$t('备注信息')" :placeholder="$t('可不填')" label-width="40%" >
                </van-field>
            </van-form>
        </div>

        <div class="btn-space btn-fixed">
            <van-button color="linear-gradient(to right, #3270e0, #3653ea)" block round @click="handleClickSave">{{$t('确认')}}</van-button>
        </div>
        <van-popup v-model="showPicker" position="bottom" round>
            <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
                    :confirm-button-text="$t('确认')"
                    :cancel-button-text="$t('取消')"
            />
        </van-popup>
        <van-popup v-model="showPickerUSDT" position="bottom" round>
            <van-picker
                    show-toolbar
                    :columns="columns_usdt"
                    @confirm="onConfirm_usdt"
                    @cancel="showPickerUSDT = false"
                    :confirm-button-text="$t('确认')"
                    :cancel-button-text="$t('取消')"
            />
        </van-popup>
    </div>
</template>

<script>
    import helper from "../../utils/helper";

    export default {
        components: {},
        data() {
            return {
                active: 0,
                loading: false,
                showPicker: false,
                showPickerUSDT: false,
                id: 0,
                name: '',
                bank_name: '',
                bank_text: '',
                bank_branch:'',
                number: '',
                remark: '',
                columns: [],
                usdt_show : false,
                columns_usdt: [
                    'ERC20', 'TRC20', 'HECO', 'OMNI'
                ],
                usdt_name: '',
                usdt_address: '',
            }
        },
        methods: {
            onClickLeft() {
                this.$router.back();
            },
            onConfirm(value, index) {
                this.bank_text = value;
                this.bank_name = this.banks[index];
                this.showPicker = false;
            },
            onConfirm_usdt(value, index) {
                this.usdt_name = value;
                this.showPickerUSDT = false;
            },
            getData() {
                this.$axios.get(this.$api_url.get_bind_bank + '?id=' + this.id).then((res) => {
                    let data = res.data;
                    if (data.code == 0) {
                        this.active = data.data.info.type ??0;
                        this.usdt_show = data.data.usdt == 1 ??false;
                        if(this.active == 1){
                            this.usdt_name = data.data.info.bank_name;
                            this.usdt_address = data.data.info.number;
                        }else{
                            this.bank_name = data.data.info.bank_name;
                            this.bank_text = data.data.info.bank_text;
                            this.bank_branch = data.data.info.bank_branch;
                            this.name = data.data.info.name;
                            this.active = data.data.info.type ??0;
                            this.number = data.data.info.number;
                            this.remark = data.data.info.remark;
                            this.columns = data.data.list;
                            this.banks = data.data.banks;
                        }
                        console.log(data)
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            handleClickSave() {
                if(this.bank_text == ''){
                    this.$toast.fail('请输入银行名称！');
                    return;
                }
                this.loading = true;
                let loading = this.$toast.loading();
                let _data = {};
                let url =  this.$api_url.save_bind_bank;
                if(this.active == 0){
                    _data = {
                        id: this.id,
                        type: 0,
                        name: this.name,
                        bank_name: this.bank_text,
                        bank_branch: this.bank_branch,
                        number: this.number,
                        remark: this.remark,
                    };
                }else{
                    url = '/bank/save_usdt';
                    _data = {
                        id: this.id,
                        type: 1,
                        bank_name: this.usdt_name,
                        number: this.usdt_address,
                    };
                }
                let data = helper.encrypt(JSON.stringify(_data));
                this.$axios
                    .post(
                        url,
                        this.$qs.stringify({
                            data: data,
                        })
                    )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$toast.success(res.data.msg);
                            this.$router.push({path: '/bank'})
                        } else {
                            this.$toast.fail({duration: 3000, message: res.data.msg});
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                        // loading.clear();
                        this.loading = false;
                    });
            },
        },
        mounted() {
            if (this.$route.query.id !== undefined) {
                this.id = this.$route.query.id;
            }
            this.getData();
        }

    }
</script>
<style scoped>
    .bg-page ::v-deep .van-nav-bar{
        background-color: #1a2331;
    }
    .bind-panel{
        background-color: #1a2331;
        padding: 15px;
        border-radius:  0 0 30px 30px;
    }
    .btn-space{
        padding: 30px;
    }
    .bind-panel ::v-deep .van-cell{
        display: block;
        background-color: transparent;
    }
    .bind-panel ::v-deep .van-field__label{
        margin-bottom: 6px;
    }
    .bind-panel ::v-deep .van-cell__value{
        background-color: #2c384a;
        border-radius: 12px;
        padding: 12px 15px;
    }
    .bind-panel ::v-deep .van-cell::after{
        display: none;
    }

    .bind-panel ::v-deep .van-field__control{
        color: #ffffff;
    }







</style>